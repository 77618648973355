.section_title{
  font-weight: 700!important;
  font-size: 16pt!important;
  color: #000!important;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgba(146,146,146,.33);
  border-bottom: 1px solid rgba(146,146,146,.33);


}
.custom-card{
  background: #f6f5f2;
  border: none;
  border-radius: 0px;
  padding: 4px;

}
.custom-right-column{
  background-color:#fff;
}
.source{
  font-size: 13px;
}
//.main-content .article-img a { max-width:200px; overflow:hidden; float:left; margin-left:0; margin-right:10px }