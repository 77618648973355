
.footer{
  //height: 60px;
  background-color: #e7e7e7;
  padding: 20px 0 10px 0;
  color: #000;
  .disclaimer{
    font-size: 12px;
    font-style: italic;
  }
  .premium{
    font-weight: 400!important;
    color: #000!important;
    font-family: Montserrat,sans-serif;
  }
  .links{
    font-size: 12px;
    a{
      color: #000;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.comment-box{
  background-color: #fff;
  padding: 20px;
  .title{
    font-size: 18px;
    font-weight: bold;
  }
  .comment{
    margin-top: 10px;
    min-height: 80px;
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    .author{
      position: absolute;
      left: 15px;
      .img-wrapper{
        display: block;
        border-radius: 50%;
        width: 50px;
        overflow: hidden;
        display: inline-block;
        img{
          height: 50px;
        }
      }
    }
    .content{
      display: inline-block;
      padding-left: 70px;
      width: 100%;
      .name{
        font-size: 14px;
        font-weight: bold;
      }
      .date{
        font-size: 12px;
        text-align: right;
        margin-bottom: 10px;
      }
      .text{
        display: block;
        width: 100%;
      }
    }
  }
  .closed{
    font-weight: bold;
    text-align: center;
  }
}


body.new-hf {
  .footer {
    background-color:#f0f1ee;
    padding: 40px 0;
    position: absolute;
    left: 0;
    width: 100%;
    margin: 50px 0 0 0;

    .links {
      a {
        color: #2962ff;
        font-size: 15px;
      }
    }
  }
}