.contact-content{
  padding-bottom: 25px;
  h1{
    margin-top: 10px;
    padding-left: 15px;
  }

  .text-container{

    padding-top: 15px;



    .authors-row{
      padding: 0;
      margin-top: 30px;
      margin-bottom: 50px;

      h3{
        margin-bottom: 30px;
      }


      .author-info{
        text-align: center;
        .author-image{
          max-height: 70px;
          display: block;
        }
        .name{
          margin-top: 10px;
          padding-top: 10px;
        }
        img{
          margin-bottom: 10px;
        }
      }
    }


  }


}
.contact-image-container{
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-filter: sepia(1);
  filter: sepia(1);
  opacity: .7;
  @media (max-width: 991px) {
    display: none;
  }

}