@font-face {
    font-family: myFirstFont;
    src: url(/user/themes/native-ad/fonts/sansation_light.woff);
  }
  
  @font-face {
    font-weight: 500;
    font-family: 'Montserrat';
    src: url('/user/themes/native-ad/fonts/Montserrat-Light.otf');
  }
  @font-face {
    font-weight: 900;
    font-family: 'Montserrat-Bold';
    src: url('/user/themes/native-ad/fonts/Montserrat-Bold.otf');
  }
  @font-face {
    font-weight: 300;
    font-family: 'Montserrat-Hairline';
    src: url('/user/themes/native-ad/fonts/Montserrat-Hairline.otf');
}


.heatpumps_v1 {
    .sponsor{
        margin-bottom: 0px;
        margin-top: 15px;

        @media (max-width: 992px) {
          margin-top: 5px;
        }
    }

    #body {
        &.v2 {
            @media (max-width: 992px) {
                margin-top: 5px !important;
            }

            @media (min-width: 576px) {
                max-width: 540px;
            }   

            @media (min-width: 768px) {
                max-width: 720px;

                .col-md-9 {
                    flex: 0 0 75% !important;
                    max-width: 75% !important;
                }
            }
        
            @media (min-width: 992px) {
                max-width: 960px;
            }
        
            @media (min-width: 1200px){
                max-width: 1140px;
            }
        }
    }

    .text-before-iframe {
        margin: auto;
    }

    .text-before-iframe-title {
        margin: auto;
        color: #0000ff;
        text-align: center;

        h2 {
            font-size: 18px !important;
            margin: 30px 15px 15px 15px;
        }
    }

    // top image banner (with title on top)
    .banner {
        width: 100%;
        height: 350px;
        color: white;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: white;

        @media only screen and (max-width: 500px) {
            height: 250px;
        }

        .title {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0, 0, 0, .45);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h1 {
                font-size: 40px;
                font-weight: bold;
                padding: 0 100px;

                @media only screen and (max-width: 1200px) {
                    font-size: 35px;
                }

                @media only screen and (max-width: 768px) {          
                    font-size: 30px;
                    text-align: center;
                    padding: 0 10px;
                }

                @media only screen and (max-width: 500px) {
                    font-size: 25px;
                }
            }

            a.cta-button {
                background-color: #7cb209;
                padding: 15px;
                margin-top: 20px;
                border-radius: 5px;
                font-weight: 600;
                font-size: 18px;
                text-transform: uppercase;
        
                @media only screen and (max-width: 500px) {
                    font-size: 14px;
                    padding: 12px;
                }

                &:hover {
                    background-color: #77aa09;
                    color: white;
                }
            }
        }
    }

    .author-row {
        margin: auto;

        .social-links {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
    
            .row {
              margin: 0;
            }
    
            .col-auto {
                padding: 0;
    
                &:first-child { padding-right: 5px; }
                &:last-child { padding-left: 5px; }
                
                h1.share-count {
                    font-size: 40px;
                    margin-bottom: 4px;
                    color: #7cb209;
        
                    @media only screen and (max-width: 500px) {
                        font-size: 30px;
                    }
        
                    span {
                        color: #6c6c6c;
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
        
                h6.share-text {
                    text-align: center;
                    color: #6c6c6c;
                    position: absolute; 
                    width: 100%;
                    padding-right: 32px;
                }
        
                button {
                    border: none;
                    background-color: #2d609b;
                    // width: 36px;
                    height: 35px;
                    padding: 0;
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    color: white;

                    @media only screen and (max-width: 576px) {
                        width: 36px;
                    }
        
                    // remove outline on click
                    &:focus {
                        outline: 0;
                        border: none;
                    }
        
                    // show tooltop on hover over
                    &:hover {
                        .btn-tooltip {
                            visibility: visible;
                        }
                    }

                    .btn-text {
                        padding: 0 10px 0 5px;

                        @media (max-width: 576px) {
                            display: none;
                        }
                    }
        
                    .btn-tooltip {
                        visibility: hidden;
                        width: 120px;
                        background-color: rgba(0, 0, 0, .7);
                        color: #fff;
                        text-align: center;
                        padding: 5px 0;
                        border-radius: 5px;
                        position: absolute;
                        z-index: 1;
                        font-size: 11px;
                        left: -38px;
                        top: -30px;
                        display: block;

                        @media only screen and (min-width: 576px) {
                            display: none;
                        }
                    }
        
                    &:first-child { margin-right: 10px; }
                    &.twitter { background-color: #55acee; }
        
                    img {
                        @media only screen and (max-width: 576px) {
                            width: 45%;
                            margin-left: 0;
                        }

                        width: 20px;
                        height: auto;
                        margin-bottom: 1px;
                        margin-left: 10px;
                    }
                }
            }
        } 
    }

    .pinel-advertorial {
        .pinel-article {
            .pinel-form-wrapper {
                min-height: 655px;

                iframe {
                    min-height: 650px;
                }   
            }
        }
    }
}