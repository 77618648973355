.left-column,.right-column{
  background-color: #eff0f1;
}
.middle-column{
  background-color: #ebeced;
}
.left-column,.right-column,.middle-column{
  h2{
    font-weight: 600;
    font-size: 12pt;
    color: #6c6c6c;
    margin: 20px 0;

  }
}
.card{
  h4{
    color: #333;
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
  }
  .card-text{
    p{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;

      color: #636363;
    }
  }
  a{
    text-decoration: none;
  }
}

