.modal-content{
  padding: 15px;
  .modal-header{
    border: none;
    .close{
      position: absolute;
      top:10px;
      right: 15px;
      font-size: 30px;
      font-weight: 800;
    }

  }
  .modal-title{
    margin-top: 15px;
    font-size: 23px;
    margin-bottom: 15px;
  }
  ol,ul{
    padding-left: 0;
    li{
      counter-increment: item;
      list-style: none;
      &:before{
        content: counter(item) ". " ;
        font-weight: bold;
      }
      padding-bottom: 10px;
    }
  }

  font-size: 13px;
}

.modal-dialog {

  @media (min-width: 576px){
    max-width: 600px;
    margin: 30px auto;
  }
  a{ color: #337ab7;}
}

#mediaModal{
  .modal-body{
    background-color: #000;
  }
  .modal-body,.modal-content,.modal-dialog{
    width: fit-content;
  }
}

#exit-modal{
  .modal-dialog{
    max-width: 577px;
  }
  .modal-content{
    background-image: url(http://conso.s3.amazonaws.com/0-revuetendancesonsocom/bck.png);
    border-radius: 16px;
    padding: 0;
  }
  h1{
    font-family: 'Oswald';
    font-size: 45px;
    color: #333;
    margin-left: -30px;
  }
  h2{
    font-family: 'Oswald';
    font-size: 25px;
    color: #333;
    margin-top: 15px;
  }
  h3{
    width: 300px;
    font-family: 'Oswald';
    font-size: 28px;
    font-weight: 700;
    color: #333;
  }
  h4{
    width: 300px;
    font-family: 'Oswald';
    font-size: 20px;
    color: #333;
    text-align: right;
  }
  .conditions{
    text-align: right;
    width: 300px;
    font-family: 'Oswald';
    font-size: 10px;
  }
  .footer-exit{
    text-align: right;
    width: 300px;
    font-family: 'Oswald';
    font-size: 17px;
  }

  .subscribe-box{
    border: 4px solid #e5e5e5;
    background-color: #fff;
    margin-top: 25px;
    margin-bottom: 30px;
    .title{
      padding-left: 20px;
      padding-top: 12px;
      font-family: 'Oswald';
      font-size: 27px;
      color: #3e9f09;
      padding-bottom: 13px;
    }
    form{
      padding: 0px 20px 20px 20px;
    }
    input[type=email]{
      display: block;
      width: 95%;
      margin-top: 15px;
      padding: 4px 12px;
      font-size: 16px;
      line-height: 2.4;
      color: #555;
      vertical-align: middle;
      background-color: #FFF;
      border: 1px solid #CCC;
      border-radius: 4px 4px 4px 4px;
      box-shadow: 0px 1px 1px #f0f0f0 inset;
      transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
      margin-bottom: 13px;
    }

    input[type=submit]{
      display: inline-block;
      margin-bottom: 13px;
      font-size: 14px;
      line-height: 1.4;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 2px 2px 2px 2px;
      padding: 10px 30px;
      background-color: #f09e09;
      color: #FFF;
      font-size: 20px;
      font-family: 'Oswald';
      margin-left: 23px;
    }
    .footer{
      text-align: right;
      font-family: 'Oswald';
      font-size: 17px;
      color: #333;
      span{
        font-size: 20px;

      }
    }
  }
}
.modal-backdrop.show{
  opacity: 0.8;
}