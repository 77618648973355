@font-face {
  font-family: myFirstFont;
  src: url(/user/themes/native-ad/fonts/sansation_light.woff);
}

@font-face {
  font-weight: 500;
  font-family: 'Montserrat';
  src: url('/user/themes/native-ad/fonts/Montserrat-Light.otf');
}
@font-face {
  font-weight: 900;
  font-family: 'Montserrat-Bold';
  src: url('/user/themes/native-ad/fonts/Montserrat-Bold.otf');
}
@font-face {
  font-weight: 300;
  font-family: 'Montserrat-Hairline';
  src: url('/user/themes/native-ad/fonts/Montserrat-Hairline.otf');
}





.navbar {
  &.header_v2 {
    box-shadow: 1px 1px 10px 2px rgba(52, 52, 52, 0.33);
    height: auto;
    margin: 0;
    padding: 0;
    background-color: grey;
    display: block;

    .title-wrap {
      display: block;
      margin-right: auto;
      margin-left: auto;
      min-height: 52px;

      h1 {
        font-size: 30px;
        color: white;
        margin-bottom: 0;
        padding-bottom: 0;
        white-space: inherit;
        padding-bottom: 5px;
        @media (max-width: 600px) {
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 15px;
          font-size: 23px;
          line-height: 1;
        }
      }

      @media (min-width: 576px) {
        width: 540px;
      }

      @media (min-width: 768px) {
        width: 720px;
      }

      @media (min-width: 992px) {
        width: 960px;
      }

      @media (min-width: 1200px){
        width: 1140px;
      }


    }
    .breadcrumb-bg{
      height: 22px;
      display: block;
      background-color: #fff;
      width: 100%;
        .text-wrap{
          height: 22px;
          margin: 0 auto;

          @media (min-width: 576px) {
            width: 540px;
          }

          @media (min-width: 768px) {
            width: 750px;
          }

          @media (min-width: 992px) {
            width: 970px;
          }

          @media (min-width: 1200px){
            width: 1140px;
          }
          .text{
            padding:0;
            margin-top: -10px;

            //line-height: 16px;
            top: -2px;
            font-weight: 600;
            position: relative;
            font-size: 11px;

            @media (max-width: 576px) {
              top: -3px;
              font-size: 10px;
              padding-right: 15px;
              padding-left: 15px;
            }

            .important{
              background-color: #d54a44;
              height: 23px;
              position: relative;
              display: inline-block;
              padding-top: 4px;
              padding-right: 5px;
              padding-left: 5px;
              color: white;
              font-weight: 500;
              margin-right: 5px;
            }
          }
          .overflow-prevent{
            white-space: nowrap;
            overflow: auto;
          }
        }
    }
  }
}

.navbar{
  &.no-links{
    .nav-content{
      margin: 0 auto;
      padding-right: 15px;
      padding-left: 15px;
      color: #000000;
      font-family: Montserrat, sans-serif;
      -webkit-font-smoothing: antialiased;
      @media (max-width: 576px) {
        margin: 0;
      }

      @media (min-width: 576px) {
        width: 750px;
      }

      @media (min-width: 992px) {
        width: 970px;
      }

      @media (min-width: 1200px) {
        width: 1170px;
      }

      @media (max-width: 992px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media (max-width: 992px) {
        padding-left: 7px;
        padding-right: 0;
      }

      @media (max-width: 768px) {
        padding-left: 0;
      }

    }
    .text-container{
      float: right;
      padding-top: 11px;
      display: inline-block;
      width: calc(100% - 290px);

      @media (max-width: 992px) {
        display: none;
      }
      
    }
  }
}

// Overwriting
#body{
  // only apply body styles to pinel pages (so that it doesn't affect VIP blog pages)
  &.pinel, &.v2, &.v2_4, &.v3 {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin-top: 81px;
    max-width: 1170px;

    @media (min-width: 768px) {
      width: 750px;
    }

    @media (min-width: 992px) {
      width: 970px;
    }

    @media (min-width: 1200px){
      width: 1170px;
    }

    @media (max-width: 992px) {
      margin-top: 30px!important;
    }

    @media only screen and (min-width: 601px) and (max-width: 766px) {
      margin-top: 99px!important;
    }
  }

  &.v2{
    margin-top: 15px;
    @media (max-width: 576px) {
      margin-top: 10px;
    }
    .main-content{
      padding-top: 0;
    }
  }

  &.v2_4{
    margin-top: 10px!important;
  }
  &.v3{
    margin-top: 0px!important;
    width: 100%;
  }
}

.pinel-advertorial {
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;

  &.mobile{
    width: 100%;
  }

  .pinel-article {
    @media only screen and (max-width: 991px) {
      .col-md-3 {
        max-width: 100%!important;
        flex: 0 0 100%!important;
      }

      .col-md-9 {
        max-width: 100%!important;
        flex: 0 0 100%!important;
      }
    }

    @media (max-width: 576px) {
      padding-top: 15px;
    }

    p{
      text-align: left;
      margin: 0;
    }
    .hidden {
      display: none;
    }

    // Overwriting
    h1{
      @media (max-width: 576px) {
        font-size: 22px;
      }
    }


    strong{
      font-family: Montserrat;
    }

    h2 {
      margin-top: 0;
      font-family: Montserrat-Bold, sans-serif;
    }

    a {
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    &.main-content .article {
      a {
        color: #145e9d;
      }
    }

    &.main-content {
      padding-top: 0;

      .title {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #333;
        @media only screen and (max-width: 992px) {
          margin-top: 40px;
          padding-top: 30px;
        }

        @media only screen and (max-width: 767px) {
          padding-top: 0;
          margin-top: 15px;
          margin-bottom: 15px;
          font-size: 22px;
        }
      }

      .share-count-container {
        @media (min-width: 768px) {
          width: 16.66666667%;
        }

        @media only screen and (max-width: 768px) {
          padding-top: 13px;
          padding-bottom: 10px;
        }

        @media only screen and (min-width: 601px) and (max-width: 766px) {
          padding-bottom: 12px;
        }

        .share-count {
          color: #0f7f12;
          font-weight: 700;
          font-size: 50px;
          margin-top: 13px;

          @media only screen and (max-width: 768px) {
            display: inline;
          }

          @media only screen and (max-width: 600px) {
            font-size: 32px;
          }

          .separator {
            padding-left: 10px;
            font-family: Montserrat-Hairline;
            @media only screen and (max-width: 1200px) {
              display: none;
            }
          }
        }

        h3 {
          margin-top: 5px;
          margin-left: 20px;
          text-align: left!important;
          @media only screen and (min-width: 768px) and (max-width: 769px) {
            position: relative;
            top: -3px;
          }
          @media only screen and (max-width: 768px) {
            display: inline;
          }
          @media only screen and (min-width: 601px) and (max-width: 766px) {
            margin-left: 19px;
          }
          @media only screen and (max-width: 600px) {
            font-size: 15px;
            margin-left: 4px;
          }
        }
      }

      .share-button-container {
        margin-top: 13px;
        margin-left: -5px;

        .social-text {
          font-size: 14px;
          margin-left: 29px;
          position: relative;
          top: -1px;
        }

        .btn {
          margin-right: 9px;

          img {
            height: 20px;
            margin-top: -1px;
          }

          &.twitter {

            img {
              padding-left: 0;
              @media only screen and (min-width: 1044px) {
                padding-left: 1px;
              }
            }

            .social-text {
              margin-left: 29px;
              @media only screen and (max-width: 1200px) {
                margin-left: 28px;
                margin-right: 1px;
              }
              @media only screen and (max-width: 768px) {
                margin-left: 29px;
                margin-right: 0;
              }
            }
          }

          @media only screen and (max-width: 1200px) {
            margin-right: 10px;
          }

          @media only screen and (max-width: 768px) {
            margin-right: 9px;
          }

          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
      }

      .author-bar {
        padding-top: 1px;

        h5 {
          margin-bottom: 14px;
        }

        h6 {
          text-transform: uppercase;
          margin: 10px 0;
        }

        .subtext-author {
          font-family: Montserrat;
          margin: 0;
          font-size: 12px;

          span {
            border-bottom: 0;
            display: block;
            padding-left: 40px;
            line-height: 17.1429px;
            margin-top: -4px;
            margin-bottom: -3px;
            @media only screen and (min-width: 993px) {
              padding-bottom: 1px;
            }
            @media only screen and (max-width: 600px) {
              margin-top: -1px;
              margin-bottom: 7px;
            }
          }
        }

        .time {
          margin-top: -10px;
        }

        hr {
          margin-left: 50%;
          margin-top: 2px;
          margin-bottom: 0;
          border-color: #eeeeee;
        }

        h6 {
          margin-top: 10px;
        }

        @media (max-width: 992px) {
          text-align: left!important;
          padding-top: 11px;
          padding-bottom: 10px;

          img {
            width: 80px;
            height: 80px;
            float: left;
          }

          h5 {
            margin: 35px 8px 0 100px;
          }

          h6 {
            margin-top: 7px;
            margin-bottom: 0;
            margin-left: 20px;
            display: inline;
          }

          .subtext-author {
            margin-top: 5px;
          }

          hr {
            display: none;
          }
        }

        @media only screen and (min-width: 601px) and (max-width: 766px) {
          padding-bottom: 12px!important;

          h6 {
            margin-top: 5px;
          }

          .subtext-author {
            margin-left: 0;
            padding-left: 40px;
            margin-top: 2px;

            span {
              border-bottom: 0;
            }
          }
        }

        @media (min-width: 768px) and (max-width: 769px) {
          padding-top: 2px;
        }

        @media (max-width: 991px) {
          padding-bottom: 10px;

          h5 {
            margin-top: 35px;
            margin-bottom: -5px;
          }

          h5, h6 {
            display: block;
            margin-left: 100px;
          }
        }

        @media (max-width: 600px) {
          padding-bottom: 4px;
          padding-top: 12px;

          .time {
            display: none;
          }

          img {
            width: 30px;
            height: 30px;
            margin-top: 5px;
          }

          h5 {
            margin-top: 0;
          }

          h5, h6 {
            display: inline;
            margin-left: 10px;
          }

          h6 {
            position: absolute;
            right: 15px;
            top: 8px;
          }

          div {
            margin-top: -5px;
            display: inline;
          }
        }
      }

    }

    //

    .pinel-v1-img {
      float: left;
      // max-height: 280px;
      // max-width: 350px;
      padding: 15px;
    }

    .mobile-image{
      display: none;
      max-width: 100%;
      @media only screen and (min-width: 601px) and (max-width: 766px) {
        padding-bottom: 20px!important;
      }
      @media (max-width: 766px) {
        display: block;
        padding: 1px 15px 10px;
        float: none;
        margin: 0 auto;
      }
    }

    .desktop-image{
      display: block;
      padding-left: 0;
      padding-top: 11px;
      margin-right: 5px;
      @media (max-width: 991px) {
        padding-top: 13px;
      }
      @media (max-width: 766px) {
        display: none;
      }
    }

    .desktop-image-v4{
      float: left;
      display: block;
      padding:10px;
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom:5px;
      margin-right: 5px;
      width: 29%;
      @media (max-width:1030px){
        //Deal with nasty ipad-pros
        width: 40%;
      }
      @media (max-width: 991px) {
        padding-top: 13px;
      }
      @media (max-width: 766px) {
        display: none;
      }
    }

    // Tooltip up the slider
    .pinel-tool-tip {
      border-radius: 3px;
      background-color: #a2b505;
      padding: 10px 15px;
      color: #fff;
      line-height: 7px;
      text-align: center;
      max-width: 500px;
      margin: 18px auto;
      position: relative;

      @media only screen and (min-width: 992px) {
        margin: 20px auto;
      }

      @media only screen and (min-width: 601px) and (max-width: 768px) {
        margin-top: 22px;
      }

      h2, h3, h4, h5, h6 {
        font-weight: 700;
        margin-bottom: 0;
        font-size: 20px;
        font-family: Montserrat-Bold;
        line-height: 26px;
      }
      h5{
        font-size: 17px;
        font-family: Montserrat;
      }
      &:after {
        border-width: 1px;
        border-style: solid;
        border-color: inherit;
        position: absolute;
        content: " ";
        width: 30px;
        height: 40px;
        border-top-color: inherit;
        border-top-width: 20px;
        border-top-style: solid;
        border-right-color: transparent;
        border-right-width: 20px;
        border-right-style: solid;
        border-left-color: transparent;
        border-left-width: 20px;
        border-left-style: solid;
        border-bottom-width: 0;
        //border-right: 18px solid transparent;
        //border-left: 18px solid transparent;
        left: calc(50% - 26px);
        bottom: -36px;
        @media only screen and (min-width: 769px) and (max-width: 1044px) {
          left: calc(50% - 25px);
        }
      }

      &.v2 {
        max-width: 100%;
      }

      span {
        font-size: 14px;
        line-height: 14px;
        position: relative;
        left: -2.1px;
      }

      img {
        height: 23px!important;
        width: 23px!important;
        position: relative;
        top: -2px;
        left: -2px;
        @media only screen and (min-width: 769px) and (max-width: 1044px) {
          left: -1px;
        }
      }
    }

    // Slider
    .pinel-form-wrapper {
      margin: 20px auto 30px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, .31);
      max-width: 500px;
      transition: all .5s ease-in-out;
      height: 605px;

      @media only screen and (max-width: 600px) {
        // height: 545px;
        height: 620px;
      }

      @media only screen and (max-width: 350px) {
        margin-left: -13px;
        margin-right: -13px;
      }

      iframe {
        width: 100%;
        min-height: 600px;
        border: none;

        @media only screen and (max-width: 766px) {
          // min-height: 540px;
          min-height: 620px;
        }
      }

      &.thin{
        height:510px;
        iframe {
          width: 100%;
          min-height: 510px;
          border: none;
        }
      }

      &.full-width {
        box-shadow: none;
        margin: 0;
        max-width: 100%;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: -100%;
        z-index: 99999999;

        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
        &.slide-in {
          left: 0;
        }
      }
      .close-btn {
        position: absolute;
        top: 12px;
        right: 0;
        font-size: 20px;
        padding-right: 20px;
        z-index: 99999999;
        background: 0 0;
        border: none;
        font-weight: 100;
      }
    }

    // Pinel box

    .pinel-box {
      margin-top: 20px;
      margin-bottom: 30px;
      background-color: #fafafa;
      border: 2px solid #145e9d;
      line-height: 1.3;
      .title-wrap {
        background-color: #145e9d;
        margin: 0;
        padding: 15px;
        color: white;

        h1, h2, h3, h4 {
          margin: 0;
        }
      }
      .content {
        padding: 15px;
        color: #0369b3;
        font-size: 18px;

        button {

          position: relative;
          color: white;
          border: none;
          cursor: pointer;
          margin: 40px auto;
          margin-top: 40px;
          background-color: #bf283b;
          padding: 15px 0 15px 20px;
          display: block;
          font-weight: 500;
          width: 100%;
          max-width: 360px;
          font-size: 18px;
          border-radius: 40px;
          text-align: center;
          img {
            position: absolute;
            height: 25px;
            display: inline-block;
            left: 20px;
            top: 14px;
          }
        }

        .small {
          line-height: 1.3;
          font-size: 12px;
          color: #444;
          .title {
            font-family: Montserrat-Bold;
            color: black;
            font-size: 14px;
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
            margin-top: 20px;
          }
        }

      }
    }

    // Collapsable panels

    .collapsable-panels {
      // width: 100%;
      padding: 30px 15px 20px;

      @media (max-width: 991px) {
        width: 100%;
      }

      @media (min-width: 992px) {
        width: 970px;
        margin-left: -243px;
      }

      @media (min-width: 1200px) {
        width: 1170px;
        margin-left: -292px;
      }

      & > .panel ~ .panel {
        .panel-collapse {
          background-color: #f6f5f2;
        }
      }

      .panel-name {
        font-weight: 700;
        font-size: 16px;
        color: #000;
        padding-left: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        background-color: #f1f0f3;
        border: 1px solid #cdcdcd;
        margin: 0;

        &:after {
          transition: all .3s ease-in-out;
          float: right;
          padding-top: 15px;
          padding-right: 10px;
          font-size: 20px;
          font-family: "Font Awesome";
          content: "\f054";
          transform: rotate(90deg);
        }

        &.collapsed {
          border-bottom: none;
          &:after {
            float: right;
            padding-top: 0;
            padding-right: 10px;
            font-size: 20px;
            font-family: "Font Awesome";
            content: "\f054";
            transform: rotate(0);

          }
        }
      }

      .panel {
        &:last-child {
          .panel-name {
            border-bottom: 1px solid #cdcdcd;
          }
          .panel-collapse {
            border-bottom: 1px solid #cdcdcd;
          }
        }

        .panel-collapse {
          padding: 15px;
          border-right: 1px solid #cdcdcd;
          border-left: 1px solid #cdcdcd;
          font-size: 13.3333px;

          .title {
            font-size: 14.6667px;
            margin-top: 0;
            margin-bottom: 0;
          }

          button {

            position: relative;
            color: white;
            border: none;
            cursor: pointer;
            margin: 20px auto;
            background-color: #bf283b;
            padding: 14px 0 14px 20px;
            display: block;
            font-weight: 600;
            width: 100%;
            max-width: 360px;
            font-size: 16px;
            border-radius: 40px;
            text-align: center;
            img {
              position: absolute;
              height: 23px;
              display: inline-block;
              left: 22px;
              top: 13px;
            }
          }

          .source {
            font-size: 9px;
            margin-bottom: 10px;
            padding-top: 10px;
          }

          a {
            font-size: 13px;
            font-weight: 700!important;
            color: #337ab7!important;
            text-decoration: none!important;
            border-bottom: 1px solid #ddd;
            line-height: 22px;
          }

        }
      }

    }

    //
    .red-link-container {
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 40px;
      a {
        font-weight: bold;
        color: #d12719;
        text-decoration: underline;
        font-size: 22px;
        font-family: Montserrat-Bold, sans-serif;
      }
    }

    .text-before-iframe {
      margin-top: 20px;
      margin-bottom: 30px;

      h2 {
        font-size: 18px;
      }

      .small {
        font-size: 14px;
      }

      .title {
        font-size: 18px;
        font-weight: 300;
        @media (max-width: 600px) {
          font-size: 17px;
        }
      }
      img{
        margin: 0 auto;
        margin-left: 30px;
        margin-bottom: 30px;
        @media (max-width: 600px) {
          width: 95%;
          padding-left:15px;
        }
      }
      .tick-list {
        font-size: 14px;
        @media (max-width: 600px) {
          font-size: 12px;
        }
        list-style-type: none;
        padding-left: 0px;
        li {
          display: list-item;
          &:before {
            content: "\f00c";
            font-family: "Font Awesome";
            padding-right: 8px;
            color: #609800;
          }
        }
      }

    }

  }
}

//desktop panel
.right-column-white{
  background-color: #fff;
  padding-top: 20px;

  &.pinel{
    font-family: Montserrat;
  }

  .panel{
    margin-bottom: 15px;
    padding: 0;

    .panel-title{
      padding: 15px;
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 12pt;
      color: #000;
      border-top: 1px solid rgba(146,146,146,.33);
      border-bottom: 1px solid rgba(146,146,146,.33);
      padding-top: 20px;
      padding-bottom: 20px;
      font-family: Montserrat-Bold, sans-serif;
    }

    .title{
      font-weight: bold;
      font-size: 16px;
    }
    .content{
      padding: 15px;
      padding-bottom: 15px;
      background-color: #f6f5f2;
      width: 100%;
      font-size: 14px;
    }

    .source{
      padding-top: 10px;
      font-size: 10px;
    }

    &:first-child{

      .content{
        background-color: transparent;
      }
    }
  }


}

.read-more-link{
  &.button{
    &.blue{
      color: white !important;
      background-color: #013582;
      text-decoration: none;
    }
    
    &.bottom{
      box-shadow: 10px 20px 100px 100px white;
      z-index: 100;
      padding: 15px;
      text-align: center;
      height: 60px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}


.pinel{
  .sponsor{
    margin-bottom: 0px;
    margin-top: 10px;
    @media (max-width: 992px) {
      margin-top: 0;
    }
  }
  &.v2{
    .sponsor {
      margin-top: 20px;
    }
  }
  &+.footer {
    padding: 8px 0 33px 0;
    width: 100%;
    margin: 0;

    @media only screen and (max-width: 600px) {
      padding-bottom: 15px;
      padding-top: 8px;
    }

    .links {
      padding: 0;
    }
  }
  &~.modal {
    font-family: Montserrat;

    .modal-content {
      padding: 30px 20px 10px;
    }
    .modal-header {
      padding: 30px 20px 14px;
      margin-right: 6px;
      .close {
        font-family: "Lexend";
        font-weight: 700;
        font-size: 32px;
        right: 20.5px;
        top: 16px;
        opacity: .2;
        cursor: pointer;
        &:hover {
          opacity: .5!important;
        }
      }
      .modal-title {
        margin-top: 0!important;
        font-size: 20px!important;
        color: #555;
      }
    }
    p {
      font-size: 12px;
      line-height: 17.1429px;
      -webkit-font-smoothing: antialiased;
    }

    ol {
      -webkit-font-smoothing: antialiased;
    }

    &#terms {
      .modal-header {
        padding: 0;
      }
    }

    &#contact {
      .modal-body {
        display: block;
        background-color: #fafafa;
        padding: 30px 30px 14px;

        h1 {
          font-size: 24px;
          margin: 20px 0 10px;
          color: #333;
        }
      }
    }
  }
  .hr-break {
    margin: 1px 0 4px;
    border-top: 1px solid #eee;

    @media only screen and (min-width: 601px) and (max-width: 991px) {
      margin: 3px 0 2px;
    }
    @media only screen and (max-width: 600px) {
      margin: -1px 0 6px;
    }
  }
}


.hidden-md{
  @media (min-width: 768px) {
    display: none;
  }
}

.hidden-sm{
  @media (max-width: 600px) {
    display: none;
  }
}
.pinel{
  strong{
    font-family: Montserrat-Bold, sans-serif;
  }
}

ol{
  &.squared{
    list-style: none;
    counter-reset: section;
    position: relative;
    padding-left: 30px;
    li{
      padding-bottom: 10px;
      &:before{
        border: 1px solid #b9b9b9;
        position: absolute;
        left: -4px;
        min-width: 23px;
        text-align: center;
        padding-top: 1px;
        padding-bottom: 1px;
        padding-right: 5px;
        padding-left: 5px;
        font-weight: 700;
        counter-increment: section;
        content: counter(section);
      }
    }
  }
}

.navbar-brand {
  margin-left: 0;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
  
  img {
    max-height: 32px;

    @media only screen and (max-width: 600px) {
      max-height: 25px;
      min-width: 100px;
      margin-left: -1px;
      margin-top: 6px;
    }
  }
}

.summary {
  position: relative;
  top: 1px;
  @media (max-width: 991px) {
    top: 3px;
  }
  @media only screen and (max-width: 600px) {
    top: -1px;
  }
  h2 {
    margin-top: 0!important;
  }

  p {
    margin-bottom: 0!important;

    a {
      font-size: 16px!important;
    }
  }

  .text-right {
    line-height: 20px;

    .read-more-link {
      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }

  &+.content {
    position: relative;
    top: 1px;

    & > h2 ~ h2 {
      margin-top: 20px;
    }
  }
}
.hide-underline{
  display: inline;
	text-decoration: none!important;
}
.show-underline{
	text-decoration: underline!important;
}

@media (max-width: 600px) {
  .share-mobile {
    display: block;
    position: absolute;
    top: 8px;
    right: 10px;

    .btn {
      
      &.facebook, &.twitter {
        width: 29px;
        height: 28px;
        background-position: center;
        position: relative;
        margin-top: 0;
        margin-right: 0;

        img {
          height: 15px;
          top: 6px;
          left: 6px;
        }

        .social-text {
          display: none;
        }
      }
    }
  }
}

.hidden-br {
  @media only screen and (min-width: 601px) and (max-width: 992px) {
    display: none;
  }
}


/*v3 content*/
body{
  color: var(--page-text-color, #000);
}
.v3-content{
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 288pt;
  margin-bottom: 7pt!important;
}
.v3.sponsor{
  margin: 0;
}
.v3.pinel-tool-tip{
  background-color: var(--slider-tooltip-color, #647ead)!important;
  border-color: var(--slider-tooltip-color, #647ead)!important;
  max-width: var(--slider-tooltip-size, 500pt)!important;
}
.img-text{
  background-color: var(--article-img-text-background-color, #00000087);
  color: var(--article-img-text-color, white);
  width: 100%;
  text-align: center;
  max-width: 320pt;
  margin: auto;
  font-size: x-large;
}
.main-img{
  max-width: 320pt;
  width: 100%;
}
.img-button{
  background-color: var(--article-img-button-background-color, grey);
  border: 2px solid var(--article-img-button-background-color, grey);
  color: var(--article-img-button-text-color, white);
  padding: 5pt;  
  border-radius: 6pt;
  display: inline;
  margin: auto;
  font-size: larger;
  padding-left: 10pt;
  padding-right: 10pt;
  transition: all ease-in-out 0.1s;
}
.img-button:hover{
  text-decoration: underline;
  text-decoration-color: var(--article-img-button-text-color, white);
  /*background-color: transparent !important;*/
  /*border: 2px solid var(--article-img-button-text-color, white);*/
  color: var(--article-img-button-text-color, white);
}

.img-button-container{
  position: absolute; 
  top: 75%; 
  width: 100%; 
  text-align: center;
}
.img-text-container{
  position: absolute;
  top: 20%;
  width: 100%;
}