.article_3_columns{
  .article{
    .main-image{
      float: left;
      margin-right: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .sidebar{
    background-color: #f1f1f1;
    border: 1px solid #999;
    margin-top: 25px;
    padding: 25px 15px 15px 15px;
    font-size: 14px;
    height: fit-content;
    img{
      margin: 0px auto 15px auto;
      display: block;
    }
  }
  .sidebar-video{

    margin-top: 25px;
    font-size: 14px;
    height: fit-content;
    .inside-wrapper{
      padding: 15px;
      background-color: #f1f1f1;
      .cardholder{
        background-color: #fff;
      }
    }
    .card-container{
      margin-bottom: 20px;
      text-align: center;
      .card-text{
        margin: 0 auto;
      }
    }
    img{
      width: 100%;
      margin-bottom: 10px;
    }
    .date{
      text-align: center;
      margin-bottom: 10px;
    }
    .title{
      color: #b60002;
      font-size: 16px;
      display: inline-block;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .subtitle{
      display: block;
      font-size:16px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  #exit-modal{
    .modal-dialog{
      max-width: 577px;
    }
    .modal-content{
      background-image: url(http://conso.s3.amazonaws.com/0-revuetendancesonsocom/bck.png);
      border-radius: 16px;
    }
    h1{
      font-family: Oswald,sans-serif;
      font-size: 49px;
      color: #333;
      margin-left: -30px;
    }
    h2{
      font-family: Oswald,sans-serif;
      font-size: 25px;
      color: #333;
      margin-top: 15px;
    }
    h3{
      width: 300px;
      font-family: Oswald,sans-serif;
      font-size: 28px;
      font-weight: 700;
      color: #333;
    }
    h4{
      width: 300px;
      font-family: Oswald,sans-serif;
      font-size: 20px;
      color: #333;
      text-align: right;
    }
    .conditions{
      text-align: right;
      width: 300px;
      font-family: Oswald,sans-serif;
      font-size: 10px;
    }
    .footer-exit{
      text-align: right;
      width: 300px;
      font-family: Oswald,sans-serif;
      font-size: 17px;
    }

    .subscribe-box{
      border: 4px solid #e5e5e5;
      background-color: #fff;
      margin-top: 25px;
      margin-bottom: 30px;
      .title{
        padding-left: 20px;
        padding-top: 12px;
        font-family: Oswald,sans-serif;
        font-size: 27px;
        color: #3e9f09;
        padding-bottom: 13px;
      }
      form{
        padding: 0px 20px 20px 20px;
      }
      input[type=email]{
        display: block;
        width: 95%;
        margin-top: 15px;
        padding: 4px 12px;
        font-size: 16px;
        line-height: 2.4;
        color: #555;
        vertical-align: middle;
        background-color: #FFF;
        border: 1px solid #CCC;
        border-radius: 4px 4px 4px 4px;
        box-shadow: 0px 1px 1px #f0f0f0 inset;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        margin-bottom: 13px;
      }

      input[type=submit]{
        display: inline-block;
        margin-bottom: 13px;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 2px 2px 2px 2px;
        padding: 10px 30px;
        background-color: #f09e09;
        color: #FFF;
        font-size: 20px;
        font-family: Oswald,sans-serif;
        margin-left: 23px;
      }
      .footer{
        text-align: right;
        font-family: Oswald,sans-serif;
        font-size: 17px;
        color: #333;
        span{
          font-size: 20px;

        }
      }
    }
  }

  .red-text {
    color: red;
    font-size: 15px;
    font-weight: 900;
    font-style: italic;
  }

  .yellow {
    background-color: #ff0;
  }

}