.btn{
  margin-right: 10px;
  &.facebook{
    background-color: #2d609b;
    color: #fff;
  }
  &.twitter{
    background-color: #55acee;
    color: #fff;
  }

  &.twitter,&.facebook {
    font-size: 14px;
    margin-top: 17px;
    @media (max-width: 576px) {
      width: 35px;
      height: 35px;
      background-position: center;
      position: relative;
    }

    img {
      display: inline-block;
      height: 18px;
      padding-right: 5px;
      position: absolute;
      @media (max-width: 576px) {
        top: 7px;
        left: 7px;
        padding: 0;
      }
    }

    .social-text {
      margin-left: 25px;
      display: inline-block;
      color: white;
      font-weight: 300;
      font-size: 12px;
      margin-bottom: -10px;
      @media (max-width: 576px) {
       display:none;
      }
    }

    &.small{
      position: relative;
      margin-top: 0;
      height: 30px;
      width: 30px;
      padding: 5px;
      margin-right: 6px;
      img{
        right: 0px;
      }
    }
  }
}

.share-div{
  .share-count-container {
    padding-right: 20px;
  }
  h1{
    font-size: 45px;
    color: #8dbc22;
    margin-bottom: 0;
  }
  h3{
    font-size: 11px;
    color: #8a8c8e;
    font-family: Arial,sans-serif;
  }
  span{
    color: grey;
    font-weight: 100;
  }
  .separator {
    position: absolute;
    margin-right: 10px;
    display: inline-block;
  }
}

.shares{
  position: absolute;
  top: 3px;
  right: 10px;
  text-align: right;
  margin-top: 5px;
  button{
    margin-top: 5px;
    padding:0;
    width: 25px;
    height: 25px;
  }
}

.share-mobile{
  display: none;
  @media (max-width: 576px) {
    display:block;
  }
}