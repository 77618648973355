@use "variables";

.homepage-header{
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  a{
    color: inherit;
    text-decoration: none;
    &:hover{
      color: inherit;
      text-decoration: none;
    }
  }
  h1{
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
    @media screen and (max-width: variables.$media-md){
      font-size: 32px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    @media screen and (max-width: variables.$media-sm){
      font-size: 28px;
    }
  }
  h2{
    font-size: 18px;
    color: #ed9c00;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  p{
    font-size: 14px;
  }
  li{
    list-style-type: none;
  }
}
.banner-articles{
  background-color: rgba(0,0,0,0.4);
  ul{
    padding-left: 20px;
    li{

      h2{
        display: block;
        @media screen and (max-width: variables.$media-md){
          font-size: 16px;
          margin-top: 10px;
        }
        @media screen and (max-width: variables.$media-sm){
          font-size: 14px;
        }
      }
      p{
        @media screen and (max-width: variables.$media-md){
          font-size: 12px;
          margin-bottom: 0;
        }

      }


    }
    @media screen and (max-width: variables.$media-md){
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: variables.$media-md){
    padding: 10px;
    margin-top: -5px;
  }

}