.unique-article-img{
  margin-bottom: 4px;
  a{
    margin: auto;
  }
  img{
    max-height: 300px;
  }
}
.main-content .article a, .unique-article-img a {
  font-weight: 600;
  text-decoration: underline;
  color: inherit;
}

+.top-side-img{
  text-align: center;
  background-color: black;
  margin-bottom: 5px;
}