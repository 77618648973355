@use "variables";

//Bar showing the category name
.category-bar{
  background-color: #fff;
  position:relative;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(125, 125, 125, 0.26);
  a{
    text-decoration: none;
    color: #000;
    font-weight: 600;
  }
  &:hover{
    text-decoration: underline;
  }
  //Small Arrow
  &:after{
    content: '';
    position: absolute;
    top: 43px;
    left: 55px;
    width: 0;
    z-index: 10;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 14px solid #fff;
  }
  &:before{
    content: '';
    position: absolute;
    top: 45px;
    left: 55px;
    width: 0;
    z-index: 10;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 14px solid rgba(125, 125, 125, 0.26);
  }
}

.navbar-nav{
  .nav-link{
    font-family: "Lexend";
    font-size: 16px;
    color: #000!important;
    transition:all .4s ease-in-out;
    border-radius: 4px;
    background-color: white;
    &:hover{
      background-color: rgba(242, 242, 242, 0.53);
      text-shadow: 0px 0px 4px rgba(12, 12, 12, 0.2);

    }
    @media (max-width: 992px){
      border-radius: 0;
      padding-left: 20px;
      background-color: white;
      &:hover{
        background-color: rgb(230, 230, 230);
      }

    }
  }

  @media (max-width: 992px){
    .nav-item{
      &:last-child{
        .nav-link{
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }



}

.active{
  .nav-link{
    font-weight: 700;
  }
}
nav{
  height: 50px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.navbar-brand{
  margin-left: 15px;
  img{
    max-height: 30px;
    max-width: 200px;
    min-width: 120px;
  }
}
.navbar-toggler{
  border: none;
  &:focus{
    outline: none;
  }
}
#navbarNav{
  margin-top: 3px;
  background-color: #fff;
  @media (max-width: 576px){
    width: 170px;
    margin-left: -16px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    li{
      border-bottom: 1px solid rgb(224, 218, 218);
      a{
        margin-left: 30px;
      }
    }
  }
}

.navbar-nav{

  @media screen and (max-width: variables.$media-md){
    filter: drop-shadow( 0px 9px 5px rgba(12,12,12,.2));
  }
}

// header
body.new-hf {
  .header {
    nav {
      max-height: 70px;
      box-shadow: 0 0 32px rgba(0,0,0,.15);
      padding-top: 16px;
      padding-bottom: 16px;
      height: auto;
    }
  }
}