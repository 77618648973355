@font-face {
    font-family: "Font Awesome";
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2)
        format("woff2");
}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

// COMMON CSS
body {
    font-family: "Lexend";
    font-size: 16px;
    font-weight: 500;
    background-color: #f6f5f2;
    color: #333;
}
h1 {
    font-size: 30px;
}
h2 {
    font-size: 22px;
}
a {
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}
#body {
    margin-top: 90px;
    @media screen and (max-width: 992px) {
        margin-top: 50px;
    }
}

bg-white {
    background-color: #fff;
}
