
// body content for new header and footer styles
body.new-hf {
  background:#fafafa;

  #body {
    margin-top: 70px;

    @media only screen and (max-width: 992px) {
      margin-top: 70px !important;
    }

    .sponsor {
      margin: 0;
      margin-right: -15px;
      margin-left: -15px;
      padding: 15px;
    }
  }
}

// all styles specifically for article_v2
.article_v2 {
  // top image banner (with title on top)
  .banner {
    width: 100%;
    height: 350px;
    color: white;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;

    @media only screen and (max-width: 500px) {
      height: 250px;
    }

    .title {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, .25);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        font-size: 50px;
        font-weight: bold;
        padding: 0 100px;

        @media only screen and (max-width: 1200px) {
          font-size: 35px;
        }

        @media only screen and (max-width: 768px) {          
          font-size: 30px;
          text-align: center;
          padding: 0 10px;
        }

        @media only screen and (max-width: 500px) {
          font-size: 25px;
        }
      }

      a.cta-button {
        background-color: #7cb209;
        padding: 15px;
        margin-top: 20px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
 
        @media only screen and (max-width: 500px) {
          font-size: 14px;
          padding: 12px;
        }

        &:hover {
          background-color: #77aa09;
          color: white;
        }
      }
    }
  }

  .author {
    margin: auto;

    .author-row {
      padding-top: 50px;
      padding-bottom: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 768px) {
        padding-top: 10px;
        padding-bottom: 35px;
      }

      @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: initial;
      }

      .author-content {
        display: inline-flex;
        align-items: center;

        @media only screen and (max-width: 500px) {
          margin-bottom: 10px;
        }

        img.rounded-circle {
          width: 40px;
          height: 40px;
        }
      
        .author-details {
          display: inline-block;
          padding-left: 10px;

          h5 {
            margin: 0;
            font-weight: bold;
            font-size: 18px;
          }

          .author-time {
            h6 {
              margin: 0;
              display: inline-block;
              font-size: 14px;
              color:  #bfbfbf;
            }
          }
        }
      }

      .social-links {
        display: flex;

        .row {
          margin: 0;
        }

        .col-auto {
          padding: 0;

          &:first-child { padding-right: 5px; }
          &:last-child { 
            padding-left: 5px; 
            margin-top: -8px;
          }
        
          h1.share-count {
            font-size: 40px;
            margin-bottom: 4px;
            color: #7cb209;

            @media only screen and (max-width: 500px) {
              font-size: 30px;
            }

            span {
              color: #6c6c6c;
              display: inline-block;
              margin-left: 10px;
            }
          }

          h6.share-text {
            text-align: center;
            color: #6c6c6c;
            position: absolute; 
            width: 100%;
            padding-right: 32px;
          }

          button {
            border: none;
            background-color: #2d609b;
            width: 36px;
            height: 35px;
            padding: 0;
            display: inline-block;
            position: relative;
            cursor: pointer;

            @media only screen and (max-width: 500px) {
              width: 31px;
              height: 30px;
            }

            // remove outline on click
            &:focus {
              outline: 0;
              border: none;
            }

            // show tooltop on hover over
            &:hover {
              .btn-tooltip {
                visibility: visible;
              }
            }

            .btn-tooltip {
              visibility: hidden;
              width: 120px;
              background-color: rgba(0, 0, 0, .7);
              color: #fff;
              text-align: center;
              padding: 5px 0;
              border-radius: 5px;
              position: absolute;
              z-index: 1;
              font-size: 11px;
              left: -38px;
              top: -30px;

              @media only screen and (max-width: 768px) {
                &.btn-tooltip-last {
                  left: -75px;
                }
              }

              @media only screen and (max-width: 500px) {
                &.btn-tooltip-last {
                  left: -38px;
                }
              }
            }

            &:first-child { margin-right: 10px; }
            &.twitter { background-color: #55acee; }

            img {
              width: 45%;
              height: auto;
              margin-bottom: 1px;
            }
          }
        }
      }    
    }
  }

  // main page content
  .main-content {
    margin: auto;

    .mb-content { margin: auto; }

    .article {
      img { margin: 20px auto; }

      // any link without a class (standard text links)
      a:not([class]) {
        color: #2962ff;
        text-decoration: none;
        border-bottom: 2px solid #2962ff;
        // padding-bottom: 2px;
      }

      h2 {
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 0.8px;

        @media only screen and (max-width: 500px) {
          margin-top: 20px;
        }
      }

      .code-box {
        border-radius: 4px;
        border: 1px solid #eee;
        box-shadow: 0 2px 12px rgba(0,0,0,.1);
        background: white;
    
        .cta {
          transition: none;
          
          &:hover {  background-color: #77aa09 !important; }
        }
      }
    }

    .author-footer {
      .author-line {
        div { color: #6c6c6c !important; }
        &:after {   width: 96%; }
      }
    }
  }


  // sidebar cards
  .card-column {
    border-left: 1px solid #bfbfbf;
    margin: 20px auto;

    @media only screen and (max-width: 991px) {
      border-left: none;
      border-top: 1px solid #bfbfbf;
    }

    .card {
      border-radius: 0%;
      border: none;

      .card-img-resize {
        width: 100%;
        height: 125px;
        background-size: cover;
        background-position: center;
      }

      .card-flex {
        display: flex;
        align-items: center;

        .card-img-left {
          min-width: 75px;
          min-height: 75px;
          background-size: cover;
          background-position: center;
        }

        .card-block {
          display: inline-flex;
          padding-left: 10px;
          flex-direction: column;
          justify-content: space-between;

          .card-title {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            padding: 0;
          }

          .card-text {
            font-size: 12px;
            color: #bfbfbf;
          }
        }
      }
    }
  }
}


.main-content{
  background-color: #fff;
  padding-top: 25px;
  &.home{
    padding-top: 0;
  }
  .title{
    font-weight: 600;
    color: #000;
  }
  .share-count-container{
    .share-count{
      margin-top: 10px;
    }
    padding-right: 10px;
  }


  .share-button-container{
    padding-left: 0;
  }

  .article-img{
    background-color: #000;
    margin-bottom: 25px;
    a{
      margin: auto;
    }
    img{
      max-height: 300px;
      width: 100%;
      height: 100%;
    }
  }

  .article{
    margin-bottom: 10px;
    .feature_logo_container{
      margin-bottom: 20px;
    }
    p{
      line-height: 18pt;
      padding-bottom: 7px;
      padding-top: 7px;
      padding-left: 0;
      margin: 0 0 10px;
    }
    h2{
      margin-bottom: 20px;
    }
    a{
      font-weight: 700;
      text-decoration: underline;
      color: inherit;
    }
    img{
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
    }
    .publisher{
      margin-top: 15px;
      margin-bottom: 15px;
      .publishers-wrapper{
        padding: 15px;
        background-color: #fcedc6;
        border-radius: 3px;
        width: 100%;
        text-align: center;
        .text{
          font-size: 13px;
          padding-right: 5px;
          @media (max-width: 576px) {
            display: inline-block;
          }
        }
        img{
          display: inline-block;
          max-width: 170px;
          max-height: 30px;
          @media (max-width: 576px) {
            display: inline-block;
            //margin-top: 10px;
            max-height: 20px;
          }
        }
      }
    }
  }




  //Author part
  .author-bar{
    h5{
      font-size: 14px;
      margin:10px 0;
      span{
        border-bottom: 1px solid #eee;
      }
    }
    h6{
      color: grey;
      margin-top: 10px;
      font-size: 12px;
    }
    img{
      width: 100px;
      height: 100px;
    }
    @media (max-width: 767px){
      text-align: left!important;
      h5{
        margin-left: 10px;
        display: inline;
      }
      h6 {
        display: inline;
      }
      img{
        width: 40px;
        height: 40px;
      }
    }

    .subtext-author{
      font-size: 13px;
      font-weight: 600;
      font-family: "Lexend";
      margin-left: 0;
    }
  }

  .author-footer{
    h3{
      font-weight: bold;
      font-size: 18px;
      @media (max-width: 992px){
        margin-bottom: 15px;
      }
    }
    img{
      //width: auto;
      //height: auto;
      max-width: 100px;
      max-height: 100px;
    }

    .authors-info{
      //padding: 0;
    }

    .author-line{
      text-align: center;
      &:after {
        width: 94%;
        right: 3%;
      }
      div{
        background-color: #fff;
        width: auto;
        display: inline-block;
        z-index: 3;
        color: #bfbfbf;
        font-weight: 700;
        position: relative;
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
      }
      &:after{
        content: '';
        width: 80%;
        border-bottom: solid 1px #bfbfbf;
        position: absolute;
        right: 15px;
        left: inherit;
        top: auto;
        z-index: 1;
        margin-top: 13px;
      }
    }
    @media (max-width: 767px){
      img{
        height: 40px;
        width: 40px;
      }
    }
    .author-image-container{
      @media (max-width: 992px){
        display: none;
      }
    }
    .author-image-next-title{
      max-height:50px;
      margin-right: 15px !important;
      @media (min-width: 992px){
        display: none;

      }
    }
  }
}

.sponsor {
  margin-top: -22px;
  font-size: 14pt;
  @media screen and (max-width: 992px){
    margin-top: 68px;
  }
  .breadcrumb-enabled{
    margin-top: 0px;
  }
}
